$primary-color :#196EA1;

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td {
    white-space: nowrap !important;
}


/* border bottom on hover */
.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0px solid #196EA1 !important;
}

.MuiDialog-root {
    z-index: 9999 !important;
}

.textAreaNew {
    font: inherit;
    color: #555555;
    width: 91%;
    border: 1px solid #d2d2d2;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 10px 12px;
    font-size: 14px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    font-weight: 500;
    line-height: normal;
    border-radius: 3px !important;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.textAreaNew::placeholder {
    color: #a6a4a4;
    opacity: 0.5;
}

.textAreaNew1 {
    font: inherit;
    color: #555555;
    width: 93%;
    border: 1px solid #d2d2d2;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 10px 12px;
    font-size: 14px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    font-weight: 500;
    line-height: normal;
    border-radius: 3px !important;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.textAreaNew1::placeholder {
    color: #a6a4a4;
    opacity: 0.5;
}

.MuiTextField-root.cursor input {
    cursor: pointer !important;
}

// border profile pic
.ant-upload.ant-upload-select-picture-card {
    border: none !important;
}

.ant-upload.ant-upload-select-picture-card {
    margin: 0 !important;
}

.navbar-brand {
    margin-right: 0 !important;
}

// ******

.ant-table-thead>tr>th {
    font-size: 19px !important;
    color: #000000DE !important;
}

.ant-table-tbody>tr>td {
    font-size: 17px !important;
    color: #000000DE !important;
    text-align: left !important;
}

.ant-modal-wrap {
    z-index: 9999;
}

.ant-modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
}

.MuiRadio-colorSecondary.Mui-checked {
    color: #289edc !important;
}

.MuiSelect-select.MuiSelect-select {
    padding-right: 32px !important;
}

.select-users {
    margin-bottom: 4% !important;
}

.dropdown-radioButton div.PrivateRadioButtonIcon-root-154 {
    margin-top: -210%;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    // color:#289edc !important; 
    color: #007bff !important;
}

.MuiFormControl-fullWidth {
    margin-right: 2% !important;
}

.makeStyles-textBoxes-23 {
    // margin-left:2%;
    display: flex;
    justify-content: space-between;
}

.makeStyles-textBoxes-425 {
    display: flex;
    justify-content: space-between;
    margin-left: "2%"
}

.numberLinesText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-width: 400px;
    white-space: pre-wrap;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2
}

.ant-select-multiple .ant-select-selection-item-remove>.anticon {
    vertical-align: 0.1em !important;
}

div.ant-select-selector {
    max-width: 375px !important;
    overflow: auto !important;
}

div.ant-select-selection-overflow {
    max-width: 360px !important;
}

// .MuiSelect-select.MuiSelect-select{
//     cursor: default !important;
// }

.MuiSelect-select.Mui-disabled {
    cursor: no-drop !important;
}

span.ant-select-clear {
    margin-right: 8px;
    margin-top: -8px
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    max-height: 100px;
}

.radio-error {
    margin: 0;
    font-size: 14px !important;
    margin-top: -18px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    margin-left: 33px;
    letter-spacing: 0.03333em;
    color: #F44336;
    margin-bottom: 5px;
}

.radio-error1 {
    margin: 0;
    font-size: 14px !important;
    margin-top: -7px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    margin-left: 33px;
    letter-spacing: 0.03333em;
    color: #F44336;
}

.url-Error {
    margin: 0;
    font-size: 14px !important;
    margin-top: 1%;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    margin-left: 3px;
    letter-spacing: 0.03333em;
    color: #F44336;
}

.ant-select-multiple .ant-select-selection-search {
    max-width: 97% !important;
}

.slick-prev:before,
.slick-next:before {
    color: #196EA1 !important;
}

.date input.MuiInputBase-input.MuiInput-input {
    cursor: pointer;
}

.timelineMap div.map {
    height: 105% !important;
}

//for dashboard design
div.card.border-left-success.shadow.h-100.py-2 {
    border-bottom: 3px solid #196ea1 !important;
    border: none;
}

div.card.border-users.shadow.h-100.py-2 {
    border-bottom: 3px solid #f05c1a !important;
    border: none;
}

div.card.border-timeline.shadow.h-100.py-2 {
    border-bottom: 3px solid #616ff9 !important;
    border: none;
}

div.card.border-post.shadow.h-100.py-2 {
    border-bottom: 3px solid #338204 !important;
    border: none;
}

div.card.border-comments.shadow.h-100.py-2 {
    border-bottom: 3px solid #1af0d4 !important;
    border: none;
}

div.card.border-like.shadow.h-100.py-2 {
    border-bottom: 3px solid #f961d7 !important;
    border: none;
}

div.card.border-permission.shadow.h-100.py-2 {
    border-bottom: 3px solid #196EA1 !important;
    border: none;
}

div.h5.mb-0.font-weight-bold.text-gray-800 {
    font-weight: 400 !important;
    font-size: 17px;
    margin-right: -19%;
}

div.h5.mb-1.font-weight-bold.text-gray-800 {
    font-weight: 600 !important;
    font-size: 1.8rem;
}

.align-items-center {
    align-items: center !important;
    justify-content: space-between !important;
}

.pb-2,
.py-2 {
    border-radius: 16px !important;
}

div.ant-tabs-tab.ant-tabs-tab-active {
    background: #fff !important;
    padding: 12px 15px !important;
}

div.ant-tabs-tab {
    padding: 12px 15px !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000 !important
}

.ant-tabs-ink-bar {
    background-color: transparent;
}

//   .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active{
//       color: black !important;
//   }

canvas.bar-chart {
    width: 480px !important;
}

.bar-heading {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;
}

@media(max-width:1000px) {
    .dropdowns {
        flex-direction: column;
    }

    .region-listing {
        margin-top: 5px;
    }

    .hover-dropdowns {
        flex-direction: column;
    }

    .dropdowns-bottom {
        justify-content: flex-start !important;
        margin-top: 4px !important;
    }
}

.download-popover {
    display: flex;
    justify-content: space-between;
    // margin-top: 2%;
    float: right;
    position: relative;
    z-index: 999;
    align-items: center;
    cursor: pointer;
}

.pointer-arrow {
    cursor: pointer;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
    color: #196ea1 !important;
}

.MuiIconButton-colorSecondary:hover {
    background-color: rgba(0, 100, 200, 0.1) !important;
}

.MuiFormControl-root {
    border-radius: 3px !important;
}

// .MuiDialog-scrollPaper {
//     justify-content: flex-end !important;
// }

.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.1) !important
}

.map-container {
    height: 400px;
}

.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
}

.makeStyles-datePicker-77 .MuiDialog-scrollPaper {
    display: flex !important;
    justify-content: center !important;
}

.ant-tabs-tab.ant-tabs-tab-disabled {
    cursor: pointer !important;
}

.MuiInputBase-input {
    height: 1.2em !important;
}

.no-data-found {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
}

.employee-list {
    white-space: pre-wrap !important;
}

div.ant-modal.post-modal {
    width: 410px !important;
}

div.ant-modal-body.post-modal {
    padding: 20px !important;
}

.comment-box {
    width: 100%;
    padding: 10px 20px;
    // height: 30%;
    display: flex;
    // justify-content: space-around;
    border: 1px solid #6c757d;
    border-radius: 20px;
    justify-content: space-between;
    // margin-left: 20%;
}

.subcomment-box {
    width: 90%;
    padding: 10px 20px;
    // height: 30%;
    display: flex;
    // justify-content: space-around;
    border: 1px solid #6c757d;
    border-radius: 20px;
    justify-content: space-between;

}

.comments-screen1 {
    display: flex;
    // justify-content: flex-end;
    flex-direction: column;
    // padding: 10px;
    align-items: flex-end;
}

.comment-screen {
    padding: 20px 20px;
}

.like-reply {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.reply-button {

    font-weight: 500;
    font-size: larger;
    cursor: pointer;
    margin-top: 12px
}

.like-reply-subcomments {
    width: 90%;
    display: flex;
    flex-direction: row;
}

.reply-button1 {
    font-weight: 500;
    font-size: larger;
}

.reply-button2 {
    font-weight: 400;
    max-width: 59vw;
    // width: 95%;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
}

.reply-button4 {
    font-weight: 400;
    max-width: 56vw;
    // width: 95%;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;
}

.reply-button3 {
    color: #6c757d;
    width: 20vw;
    // font-size: larger;
}

.image-center {
    text-align: center !important;
}

.user-image {
    // margin-right: 4%;
    display: flex;
    // align-items: center;
}

//css change for mui drawer
.MuiDrawer-paper {
    z-index: 0 !important;
}

.no-comments {
    display: flex;
    font-size: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translate(10px, 50px);
    // padding: 135px 40px;
}

.ant-image-mask {
    border-radius: 50px !important;
}

.reply-modal {
    margin-top: -6px;
}

.ant-image-mask-info .anticon {
    vertical-align: 0.1em !important;
}

.cross-icon {
    background-color: rgb(25, 110, 161);
    position: absolute;
    top: 2%;
    margin-left: 25%;
    height: 20px;
    width: 20px;
    align-items: center;
    justify-content: center;
    /* right: -1%; */
    border-radius: 10px;
    display: flex;
}

.MuiAppBar-root {
    z-index: 993 !important;
}

.Toastify__toast-container {
    z-index: 200000 !important;
}

.location_details {
    font: inherit;
    color: #555555;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #d2d2d2;
    padding: 10px 12px;
}

.rating_details {
    font: inherit;
    color: #555555;
    font-size: 14px;
    -webkit-tap-highlight-color: transparent;
    font-weight: 700;
}

.video-class {
    right: 2%;
    position: absolute;
    cursor: pointer;
    top: 2%;
    z-index: 999;
    background-color: #196ea1 !important;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center
}

.post-locations {
    padding: 7px;
    background-color: #fff;
    width: 100%;
    border: 1px solid #d2d2d2;
}

.reply_text {
    color: #000;
    font-size: 20px
}

.submit_text {
    align-items: center;
    justify-content: space-around;
    display: flex;
    margin-top: 20px;
}

.reactions_class {
    margin-right: 30px;
    margin-top: 10px;
    margin-bottom: 5px;
    cursor: pointer
}

.user_image1 {
    margin-right: 10px;
    border-radius: 25px;
    border: 1px solid lightgray;
}

.grey_line {
    height: 25px;
    width: 1px;
    border: 1px solid grey;
    margin-right: 5px;
    margin-top: 12px;
    margin-bottom: 5px;
}

.thumbs_up_image {
    margin-right: 2px;
    margin-top: 11px;
    margin-bottom: 5px;
    height: 30px;
    width: 30px
}

.no_of_likes {
    margin-right: 5px;
    margin-top: 16px
}

.commenting_area {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    bottom: 5px;
    background-color: white;
    position: absolute;
    width: 95%
}

.reply_edit_modal {
    margin-top: 10px
}

.align_comment_screen {
    display: flex;
}
.extra_time{
    font-size: 14px;
    font-weight: 400;
    color: gray;
}

//rich editor
.rdw-image-alignment {
    display: inline !important;
    text-align: inherit !important;
}

.rdw-image-center {
    display: flex !important;
    justify-content: center;
}

.rdw-image-right {
    float: right !important;
}

.rdw-image-left {
    float: left !important;
}

.rdw-center-aligned-block * {
    text-align: center !important;
}

.public-DraftStyleDefault-ltr {
    text-align: inherit !important;
}

.public-DraftStyleDefault-block {
    margin: unset;
}

.card_fonts{
    font-size: 1.4rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

div.compact-picker{
    width: 293px !important;
}

.linear_gradient_colors{
    display:flex ;
    flex-direction: row ;
}

@media(max-width:1192px){
    .linear_gradient_colors{
        display:flex;
        flex-direction: column;
    }
}

ul.recharts-default-legend{
    display:none
}

.MuiPaper-elevation24{
    box-shadow: none !important;
}

.bar_colors{
    color: #000000DE;
    width: 217px;
    font-size: 17px;
    font-weight: 500;
}


// .editor-class figure {
//     margin: 0;
// }

// .editor-class .rdw-image-left {
//     display: unset;
//     float: left !important;
// }

// .editor-class .rdw-image-right {
//     display: unset;
//     justify-content: unset;
//     float: right !important;
// }