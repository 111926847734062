/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');*/

@import "~antd/dist/antd.css";

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./fonts/Montserrat-Regular.woff2") format("woff2"),
    url("./fonts/Montserrat-Regular.woff") format("woff"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./fonts/Montserrat-Medium.woff2") format("woff2"),
    url("./fonts/Montserrat-Medium.woff") format("woff"),
    url("./fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("./fonts/Montserrat-SemiBold.woff") format("woff"),
    url("./fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "arial";
  src: url("./fonts/arial.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("./fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Thin";
  src: url("./fonts/Poppins-Thin.ttf") format("truetype");
}

/* div#__next{
    background-color: #1c1c1c ;

} */

* {
  /* background-color: #1c1c1c ; */
  margin: 0;
  padding: 0;
}

header {
  padding: 8px 0 8px;
  position: absolute;
  width: 100%;
  z-index: 99;
  top: 0;
}

.mobile-icons {
  display: none !important;
}

div#collapsibleNavbar ul li .nav-link {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  transition: 0.5s;
  padding: 0;
}

section.slider {
  /* background-image: url("../public/images/map3.png"); */
  padding: 120px 0 0px 0;
  position: relative;
  padding-top: 160px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  min-height: 100vh;
  background-size: 100% 100%;
  background-attachment: fixed;
}

section.slider h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  line-height: 50px;
  font-family: "Montserrat-SemiBold", sans-serif;
}

section.slider p {
  font-size: 17px;
  /* text-align: justify; */
  text-transform: capitalize;
  /* margin-top: 17px;     */
  /* margin-bottom: 22px; */
  font-weight: 500;
  color: #fff;
  /* line-height: 30px; */
  -webkit-text-size-adjust: 100%;
  /* width: 100%; */
  font-weight: 400;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
}

p.BLUE {
  background-color: #368efa;
  color: #fff;
  font-size: 14px;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 0 !important;
}

.Mobile_1 {
  position: relative;
}

p {
  margin-top: 0;
  margin-bottom: 0rem !important;
}

/* .date [role='button'], input:not([type='range']){
  cursor: pointer;
} */
/* a, area, button, [role='button'], input:not([type='range']), select{}
  cursor: pointer;
} */

h2.heading {
  text-align: center;
  display: inline-block;
  width: 100%;
  font-weight: 500;
  font-size: 30px;
  color: #7f1223;
  font-family: "Montserrat-Medium", sans-serif;
}

p.heading-paragraph {
  font-size: 16px;
  font-weight: 400;
  color: #515151;
  margin-top: 10px;
  display: inline-block;
  width: 100%;
}

.margin-top-40 {
  display: block;
  margin-top: 40px;
  width: 100%;
}

.second_section h3 {
  font-size: 21px;
  color: #7f1223;
  font-weight: 500;
  margin-top: -11px;
  margin-bottom: 9px;
  font-family: "Montserrat-Medium", sans-serif;
}

.second_section p {
  font-size: 14px;
  color: #7f1223;
  font-weight: 500;
  line-height: 19px;
  font-family: "Montserrat-SemiBold", sans-serif;
  padding: 0 16px;
}

.second_box {
  padding: 2px 15px 17px;
  display: block;
  width: 100%;
  transition: 0.5s;
  border-radius: 8px;
}

.second_box:hover {
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
}

section.second_section {
  padding-bottom: 72px;
  /*        background-color: #f4f4f4;
*/
}

button.btn.btn-primary {
  background-color: #f1801f;
  border-color: #f1801f;
  font-size: 21px;
  font-weight: 300;
  border-radius: 50px;
  padding: 0px 38px;
  height: 60px;
}

button.btn.btn-primary.bg-white:hover {
  background-color: #f1801f !important;
  color: #fff !important;
  border-color: #f1801f !important;
}

button.btn.btn-primary.bg-white,
button.btn.btn-primary:hover {
  background-color: #fff;
  color: #f1801f;
  border-color: #f1801f;
}

section.clean_interface p {
  font-size: 16px;
  color: #515151;
  line-height: 26px;
  font-weight: 400;
  margin-top: 31px;
  margin-bottom: 50px;
}

.mobile_circle {
  height: 360px;
  width: 360px;
  background-color: #f1801f;
  border-radius: 50%;
  border: 100px solid #f8bf8f;
  box-sizing: content-box;
  text-align: center;
  position: relative;
  float: right;
}

.mobile_circle img {
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
  left: 0;
  bottom: 0;
}

.align-item-center {
  align-items: center;
}

section.clean_interface {
  padding-bottom: 105px;
}

.mobile-cover {
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  margin: 10px auto 0px;
  z-index: 999;
  text-align: center;
  width: 261px;
}

/*
section.screenshot .owl-item.active.center .item {
    overflow: hidden;
    border-radius: 20px;
    height: 488px;
    width: 227px;
    margin-left: -14px;
    margin-top: 0px;
}

section.screenshot .owl-item.active.center .item img {
    transform: scale(1.1);
     transition: 0.5s;
}*/

section.screenshot .owl-carousel.owl-theme.owl-loaded.owl-drag {
  margin-top: -23px;
}

section.screenshot p.heading-paragraph {
  margin-bottom: 60px;
}

section.screenshot .owl-dots button {
  height: 20px;
  width: 20px;
  background-color: #fbd0aa !important;
  display: inline-block;
  border: 0px solid red !important;
  border-radius: 100px;
  margin: 4px;
}

section.screenshot .owl-dots button.active {
  background-color: #f1801f !important;
}

section.screenshot .owl-dots {
  text-align: center;
  margin-top: 100px;
}

/* section.screenshot {
    padding-bottom: 0px!important;
    background-image: url("../public/images/");
    padding: 33px 0;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 100%;
} */

section.testmonials {
  background-color: #f3f3f3;
  padding-top: 90px;
  padding-bottom: 90px;
}

section.testmonials .owl-carousel .owl-item img.quotes_png {
  height: 80px;
  width: auto !important;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.5s;
  transform: translateY(100px);
}

section.testmonials .owl-carousel .owl-item img.quotes_2 {
  height: 80px;
  width: auto !important;
  position: absolute;
  bottom: 0;
  left: 0;
}

section.testmonials .owl-carousel span.profile_pic {
  display: block;
  height: 70px;
  width: 70px;
  border: 0px solid red;
  border-radius: 70px;
  overflow: hidden;
  display: inline-block;
  margin: 0 auto 25px;
}

section.testmonials .owl-carousel span.Name {
  font-size: 21px;
  color: #222222;
  font-weight: 400;
}

section.testmonials .owl-carousel span.Name {
  font-size: 21px;
  color: #222222;
  font-weight: 400;
  display: block;
  text-align: left;
  /* clear: left; */
}

section.testmonials h2.heading {
  margin-bottom: 50px;
}

section.testmonials .owl-carousel p {
  font-size: 16px;
  color: #515151;
  font-weight: 400;
  margin-top: 7px;
  height: 120px;
  display: block;
  text-align: left;
  overflow: hidden;
}

#slider2 .item {
  position: relative;
  padding-bottom: 90px;
  background-color: #fff;
  box-shadow: 0px 3px 6.51px 0.49px rgba(0, 0, 0, 0.26);
  padding: 20px 20px 95px;
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
  transition: 0.5s;
}

#slider2 .owl-item {
  padding: 13px;
}

#slider2 .owl-item:hover .item {
  background-color: #f1801f;
}

#slider2 .owl-item:hover .item span.Name,
#slider2 .owl-item:hover .item p {
  color: #fff;
}

#slider2 .owl-item:hover img.quotes_2 {
  display: none;
}

#slider2 .owl-item:hover .quotes_png {
  transform: translateY(0px);
  transition: 0.5s;
}

/* .footer-box {
    background-image: url("../public/images/footer-bg.png");
    padding-top: 80px;
    text-align: center;
    padding-bottom: 80px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    background-attachment: fixed;
} */

.footer-box h2 {
  font-size: 38px;
  color: #fff;
  text-align: center;
}

.footer-box p {
  font-size: 16px;
  color: #fff;
  font-weight: 300;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 27px;
}

.footer-bottom ul li {
  display: inline-block;
  padding: 0px 7px;
}

section.foter_bottom {
  border-bottom: 3px solid #fff;
  padding-bottom: 10px;
}

section.foter_bottom2 {
  text-align: center;
  padding: 21px 0;
  color: #fff;
}

section.foter_bottom2 p {
  margin-bottom: 0px;
}

/*

:hover {
    transform: translate(0px, -3px);
    opacity: 0.8;
}*/

section.slider img,
.second_box img,
.footer-bottom ul li img,
.mobile_circle img {
  transition: 0.5s;
}

section.slider img:hover,
.footer-bottom ul li img:hover {
  transform: translate(0px, -3px);
}

.second_box:hover img {
  transform: scale(1.1);
}

.owl-carousel#slider2 .owl-item img {
  display: block;
  width: 100%;
  object-fit: cover;
  height: 80px;
}

.mobile_circle:hover img {
  transform: scale(0.9);
}

.Mobile_1.slidetilUp.wow img {
  width: 68%;
  position: relative;
  top: 6px;
  right: 0;
  text-align: left;
  margin-right: auto;
}

.content_align {
  display: flex;
  width: 100%;
  align-items: center;
}

/*.col-sm-5.text-center {
    position: absolute;
    top: 102px;
    right: 82px;
}
*/

.container-fluid.adjust {
  max-width: 1242px;
  width: 100%;
}

.button-lite {
  background-color: #000000;
  border: 0;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 2.72;
  min-width: 190px;
  padding: 0 1.9286em;
}

section.second_section .second_box {
  box-shadow: 0px 0px 77.08px 16.92px rgba(127, 18, 50, 0.14);
}

section.screenshot .heading img {
  margin-right: 9px;
  position: relative;
  top: -2px;
}

section.screenshot h2 {
  margin-top: 7px;
  margin-bottom: 65px;
}

#more,
#more1,
#more2 {
  display: none;
}

button#myBtn,
button#myBtn1,
button#myBtn2 {
  border: 0;
  color: #7f1223;
  font-weight: 700;
  font-family: "Montserrat-SemiBold", sans-serif;
}

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

p#line {
  min-height: 187px;
}

.second_box {
  min-height: 483px !important;
}

span.spaceer {
  margin-top: 5px !important;
  display: block;
}

/*.online-doctor{
    background-image: url(../public/images/image.png);
    padding: 206px 0 202px 0;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}*/

/* footer {
    background-image: url("../public/images/footer.png");
    padding: 146px 0 106px 0;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-color: #fafafa;
} */

.menu a {
  color: #7f1223;
  font-weight: 500;
  padding-right: 17px;
  font-family: "Montserrat-SemiBold", sans-serif;
}

.col-sm-6.text-right.slideInLeft.wow a {
  color: #7c1020;
  font-weight: 500;
  font-family: "Montserrat-SemiBold", sans-serif;
}

section.foter_bottom2 p {
  margin-bottom: 0px;
  color: #545454;
  border-top: 1px solid #7f1223;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;

  padding-top: 14px;
}

html,
body {
  /*font-family: 'Montserrat', sans-serif;*/
  /* background-color: #fafafa; */
}

section.online-doctor img {
  width: 100%;
}

section.online-doctor {
  margin-top: 146px;
}

.darkHeader {
  background-color: #fff;
}

header.fixed-top.clearHeader {
  background-color: rgba(0, 0, 0, 1) !important;
  box-shadow: 0px 0px 77.08px 16.92px rgb(127 18 50 / 14%);
  height: 121px;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: block;
}

button.owl-prev {
  position: absolute;
  right: 0;
  top: 36%;
}

button.owl-next {
  position: absolute;
  left: 0;
  top: 35%;
}

i.fa.fa-angle-left,
i.fa.fa-angle-right {
  font-size: 62px;
  color: #7f1223;
}

.owl-dots.disabled {
  display: none !important;
}

.socail-media h2 {
  font-size: 21px;
  color: #7f1223;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
}

.socail-media {
  text-align: center;
  margin-bottom: 33px;
}

.owl-carousel.owl-drag .owl-item {
  width: 481px;
  height: 436px;
  border-radius: 12px;
}

.owl-carousel .owl-item img {
  border-radius: 12px;
  height: 351px;
  width: 485px;
  margin: auto;
}

/* section.slider.about-us {
    background-image: url("../public/images/about.png");
    padding: 221px 0 160px 0;
} */

.text-center.about {
  text-align: center;
  margin: auto;
}

.text-center.about.heading h2 {
  color: #fff;
}

.headings h2 {
  color: #fff !important;
  line-height: 21px;
}

section.slider .headings p {
  font-size: 18px;
  color: #fff;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  font-family: "Montserrat-Medium", sans-serif;
}

.about img {
  width: 100%;
}

.col-sm-6.text-right p {
  text-align: left;
  color: #757373;
  font-size: 14px;
}

.about-us-page {
  padding-top: 0px;
  background-color: #fff;
  padding-bottom: 0px;
  margin-top: -22px;
}

.about-right {
  padding-top: 108px;
}

.about-us-page p {
  font-size: 15px;
  text-align: left;
  color: #757373;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
}

/*header.fixed-top.clearHeader {
    background-color: #fff;
}*/

p.line i {
  font-size: 19px !important;
  color: #fff !important;
  position: relative;
  top: 2px;
  padding: 0 5px;
}

.container.adjust {
  max-width: 1310px;
}

/* body .chronic {
    background-image: url"(../public/images/chronic.png")
    padding: 221px 0 160px 0;
    background-color: #fff;
} */

.chronic_text h2 {
  font-size: 18px;
  color: #5a5959;
  font-weight: 500;
  text-align: center;
  margin: auto;
  margin-top: 19px;
  font-family: "Montserrat-Medium", sans-serif;
}

section.chronic_text {
  padding: 39px 0;
}

span.color {
  color: #7f1223;
  font-weight: 700;
  opacity: 1;
  font-family: "Montserrat-SemiBold", sans-serif;
}

/* section.chronic_text {
    background-image: url("../public/images/bg-color.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    padding: 0 0 54px 0;
} */

.chronic-box {
  max-width: 650px;
  margin: auto;
  padding-top: 36px;
  text-align: center;
}

.chronic-box p {
  color: #5a5959;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
}

.chronic-box h2 {
  margin-bottom: 21px;
}

.chronic-box .container.adjust h2 {
  font-size: 26px;
}

.col-sm-5.text-right.slideInLeft.chronic-left {
  text-align: left;
  padding-left: 63px;
  margin-top: 209px;
}

.chronic-left h2.heading {
  text-align: left;
}

.about-us-page.chronics {
  background-color: #f4f4f4;
}

.outer-text {
  background: linear-gradient(to bottom, #e4596e 43%, #811324 95%);
  padding: 34px 48px;
  height: 128px;

  border-radius: 7px;
}

input.form-control {
  height: 56px;
  border-radius: 30px;
  border: 0;
  font-size: 15px;
  color: #000 !important;
}

.message-box {
  position: relative;
}

.message-box button {
  border: 0;
  width: 155px;
  height: 54px;
  /* background-color: red; */
  background-image: linear-gradient(#b8283f, #821324);
  color: #fff;
  border-radius: 30px;
  position: absolute;
  right: 49px;
  top: 35px;
  font-size: 19px;
  line-height: 17px;
  font-family: "Montserrat-Medium", sans-serif;
}

.message-box {
  margin-top: 39px;
}

.slider.join {
  /* background-image: url("../public/images/join-bg.png"); */
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  padding: 262px 0 250px 0;
}

.wrap {
  text-align: left;
}

.col-sm-5.text-right.slideInLeft.chronic-left.join {
  margin-top: 62px;
}

.wrap.new-line p {
  color: #7f1223;
  text-decoration: underline;
}

.wrap h4 {
  color: #1f1f1f;
  font-size: 24px;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
}

.about-us-page.joiner {
  box-shadow: 0px 0px 26px 14px rgb(127 18 35 / 9%);
  padding-top: 26px;
  background-color: #fff;
  padding-bottom: 66px;
}

.new-fine img {
  width: 100%;
  box-shadow: 0px 0px 77.08px 16.92px rgb(127 18 35 / 14%);
}

.new-fine h2 {
  color: #fff;
  font-weight: 600;
  font-size: 40px;
}

.new-fine p {
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
}

.new-fine {
  position: relative;
}

.img_div {
  position: absolute;
  bottom: 37px;
  text-align: center;
}

section.board {
  margin-top: -93px;
  padding-bottom: 49px;
}

.joiner h2.heading {
  font-weight: 600;
  margin-bottom: 62px;
}

.joiner .about img {
  width: 93%;
  box-shadow: 10px 33px 107.08px 8.92px #fcf5f7;
  border-radius: 6px;
  height: 447px;
}

.joiner .container.adjust {
  max-width: 1240px;
}

footer.join_footer {
  background-color: #fff;
}

/* section.slider.contactus {
    background-image: url("../public/images/contactus.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    padding: 262px 0 250px 0;
} */
.img-shad {
  /* background-image: url('../public/images/box.png'); */
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: inherit;
  padding: 27px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  height: 212px;
}

.text-data {
  /* box-shadow: 0px 0px 26px 14px rgb(127 86 50 / 9%); */
  margin-top: -92px;
  /* background-color: #fff; */
  position: relative;
  /* overflow: hidden; */
  max-width: 500px;
  padding: 0;
  margin: auto;
  width: 100%;
}

.contact-us input.form-control {
  box-shadow: 0 0 8px 8px #293340;
  padding-left: 27px;
  padding-right: 27px;
  font-size: 14px;
  height: 46px;
  margin-top: 17px;
}

.contact-us textarea.form-control {
  height: 200px;
  resize: none;
  box-shadow: 0 0 8px 8px #293340;
  border: 0;
  border-radius: 20px;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  padding-left: 27px;
  padding-right: 27px;
  padding-top: 15px;
  margin-bottom: 29px;
}

.contact-us textarea.form-control::placeholder {
  color: #a6a4a4;
}

.contact-us input.form-control::placeholder {
  color: #a6a4a4;
}

.social-icons-new {
  text-align: center;
  margin-top: 50px;
}

.social-icons-new .button-lite {
  /* padding: 12px 40px; */
  color: #fff;
  border-radius: 4px;
  border: 0px;
}

.chronic-box.box .color {
  font-weight: 500;
}

.chronic-box.box {
  max-width: 971px;
}

.wrapper-box {
  max-width: 723px;
  margin: auto;
  text-align: center;
}

.doctor {
  text-align: center;
  margin: auto;
}

.chronic-box.box .doctor p {
  margin-bottom: 7px;
  font-size: 16px;
  color: #434242;
}

.chronic-box.box .doctor h2 {
  margin-bottom: 8px;
  font-size: 24px;
  font-family: "Montserrat-Medium", sans-serif;
}

.chronic-box.box p.lines {
  color: #7f1223;
  font-weight: 500;
  font-size: 17px;
  font-family: "Montserrat-Medium", sans-serif;
}

.chronic-box.box p {
  color: #5a5959;
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
  text-align: justify;
}

.img_map img {
  width: 100%;
}

.message-box {
  margin-top: 11px;
}

.message_earned {
  max-width: 743px;
  margin: auto;
  margin-top: 24px;
}

.new-message p {
  color: #757373;
  text-align: center;
  padding: 13px 0;
  font-size: 17px;
  font-weight: 500;
  font-size: 15px;

  font-family: "Montserrat-Medium", sans-serif;
}

.message-box.box {
  margin-bottom: 33px;
}

.new-message h2.heading {
  margin-top: 23px;
}

.message_earned.new {
  margin-top: 0;
}

.message-box.box button {
  background-color: #e4596e !important;
  background-image: none;
}

.modal-body.new-div {
  display: flex;
  justify-content: space-between;
}

.new-div img {
  width: 40%;
  margin-top: 19px;
}

.modal-header img {
  position: absolute;
  top: -16px;
  right: -18px;
  opacity: 1;
}

h3.doctor {
  margin: 104px 0 45px 0;
  color: #7f1223;
  font-size: 21px;
}

p.Download {
  font-weight: 500;
  margin-bottom: 29px;
}

.modal-body {
  text-align: center;
}

.modal-dialog {
  max-width: 686px;
  margin-top: 150px;
}

.new-div {
  display: flex;
  justify-content: space-around;
  width: 93%;
  margin: auto;
}

button.close {
  opacity: 1;
}

.modal-header {
  border-bottom: 0;
}

.modal-content {
  border-radius: 13px;
}

button.close {
  opacity: 1;
}

/*.modal-open {
    overflow: auto;
}*/

div#collapsibleNavbar ul li.active a {
  color: #368efa;
  font-weight: 700;
  font-family: "Montserrat-Medium", sans-serif;
}

ul.navbar-nav {
  white-space: nowrap;
}

.social-icons a:hover {
  color: #fff;
  text-decoration: none;
}

.openChatBtn {
  background-color: rgb(123, 28, 179);
  color: white;
  padding: 16px 20px;
  border: none;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
  max-height: calc(100vh - 123px);
}

.openChat {
  display: none;
  position: fixed;
  bottom: 120px;
  right: 15px;
  box-shadow: 0px 0px 77.08px 16.92px rgb(127 18 50 / 14%);
  z-index: 999;
  max-height: calc(100vh - 124px);
  overflow: auto;
  max-width: 400px;
  width: 100%;
}

form {
  /* max-width: 300px; */
  /* padding: 10px; */
  /* background-color: white; */
}

form textarea {
  width: 100%;
  font-size: 18px;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  font-weight: 500;
  background: #d5e7ff;
  color: rgb(0, 0, 0);
  resize: none;
  min-height: 100px;
}

form textarea:focus {
  /* background-color: rgb(219, 255, 252); */
  outline: none;
  border-radius: 2px;
}

form .btn {
  background-color: rgb(34, 197, 107);
  color: white;
  padding: 16px 20px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

form .close {
  background-color: red;
}

form .btn:hover,
.openChatBtn:hover {
  opacity: 1;
}

.button_live {
  position: fixed;
  right: 50px;
  bottom: 50px;
}

.openChat.block {
  display: block;
}

.openChat .wrapper {
  max-width: 400px;
  background-color: #e2d2d7;
  width: 100%;
}

.openChat textarea.form-control {
  height: 200px;
  border-radius: 12px;
  resize: none;
  color: #000;
}

.openChat h1 {
  background-color: #ad2439;
  color: #fff;
  font-size: 27px;
  font-weight: 400;
  padding: 16px;
}

.chat_line {
  padding: 12px 21px 12px 26px;
}

.form-control:focus {
  border-color: none !important;
  box-shadow: none;
}

.chat_line input.form-control {
  font-size: 15px;
}

.box-color {
  margin: 20px;
  background-image: linear-gradient(#b8283f, #821324);
  padding: 10px 10px 3px;
  box-shadow: 0 0 12px #c38a93;
  border-radius: 14px;
  color: #fff;
}

.social-icons.button {
  margin-top: 42px;
  text-align: center;
}

p.text-fine {
  font-size: 13px;
  padding-top: 37px;
  color: #000;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
  text-shadow: none;
}

.owl-dots {
  display: none;
}

section.second_section .second_box img {
  width: 200px;
  height: 200px;
}

h4.new-liness {
  text-align: center;
  font-size: 20px;
  color: #8c2939;
  margin-top: 14px;
  font-weight: 600;
}

.wrap.new-line p a {
  color: #991c30;
  font-family: "Montserrat-SemiBold", sans-serif;
}

header.fixed-top.clearHeader {
  background-color: #fff;
}

header.fixed-top.clearHeader.header-view {
  background-color: transparent;
}

.maps {
  box-shadow: 0 0 12px #bbbbbb;
  margin-top: 17px;
  position: relative;
}

.button.lites.color {
  padding: 12px 14px;
  border-radius: 27px;
  background-image: linear-gradient(#b8283f, #821324);
  top: 103px;
  position: absolute;
  color: #fff;
  left: 12px;
}

.directions-card.directions-card-medium-large {
  display: none !important;
}

.button.lites.color img {
  padding-right: 8px;
}

img.lite-img {
  position: absolute;
  bottom: -89px;
  right: -51px;
  z-index: 9;
  width: 17%;
}

sup.top-text {
  font-size: 31px;
}

span.radius {
  text-transform: lowercase;
}

.second_section button {
  background-color: transparent;
}

nav.navbar.navbar-expand-lg {
  padding-left: 0;
}

/*div#collapsibleNavbar ul li a:hover{
        color: #7f1223;

}*/
.headings.join {
  margin-top: 39px;
}

.img_div.last {
  position: absolute;
  bottom: 85px !important;
}

.socail-media a:nth-child(2) {
  margin-right: 19px;
}

.chronic-box .container.adjust h2 img {
  position: relative;
  top: -2px;
  padding-right: 5px;
}

.doctor p {
  text-align: center !important;
}

.new-message h2 img {
  margin-top: -4px;
  padding-right: 8px;
}

.outer-text input {
  font-size: 14px;
  font-family: "Montserrat-Medium", sans-serif;
  font-weight: 500;
  padding-left: 29px;
  padding-right: 182px;
}

section.slider.chronic .about .headings {
  margin-top: 100px;
}

.about-us-page.chronics .heading img {
  position: relative;
  top: -2px;
  padding-right: 7px;
}

h2.heading.import .color {
  font-size: 32px;
}

h2.heading.import img {
  position: relative;
  top: -6px;
  right: 8px;
}

.message-box.small button {
  font-size: 14px;
  max-width: 184px;
  width: 100%;
}

.chronic-box.newBox {
  max-width: 720px;
}

.message-box.small {
  margin-bottom: 19px;
  margin-top: 36px;
}

.message-box.small input {
  padding-right: 207px;
}

.about-right {
  padding-top: 108px;
  padding-right: 35px;
  padding-left: 0;
  margin-left: -16px;
}

section.slider.about-us .headings {
  margin-top: 90px;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: transparent !important;
}

.message_earned.new p {
  margin-bottom: 30px;
}

.message-box.box button {
  width: 195px;
}

.footer-bottom {
  margin-top: 50px;
}

.container-fluid.adjust.cro h2 {
  margin-bottom: 11px;
  margin-top: 0;
}

.new-line-md h2 {
  font-size: 17px;
  color: #7f1223;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
}

.new-line-md p {
  font-size: 15px;
  color: #7f1223;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
}

.new-line-md {
  text-align: center;
  box-shadow: 0px 0px 77.08px 16.92px rgb(127 18 50 / 14%);
}

.new-line-md {
  text-align: center;
  border-radius: 13px;
  transition: 0.4s ease-out;

  /*        min-height: 335px;
*/
  box-shadow: 0px 0px 77.08px 16.92px rgb(127 18 50 / 14%);
}

/*.new-bottom-box {
    box-shadow: 0px 0px 77.08px 16.92px rgb(127 18 50 / 14%);
}*/
.new-line-md img {
  width: 110px;
  /* object-fit: none; */
}

.new-bottom-box {
  padding: 11px 13px 1px;
}

.new-bottom-box {
  min-height: 161px;
}

section.medicience {
  background-color: #f4f4f4;
  padding: 54px 0 67px 0;
}

/*.logo_flex {
    display: flex;
    justify-content: center;
    align-items: center;
}*/
img.liner {
  position: absolute;
  left: 0;
  top: -25px;
}

section.medicience .container {
  position: relative;
}

.logo_flex h2 {
  margin-bottom: 63px;
}

.logo_flex h2 img {
  position: relative;
  padding-right: 9px;
  top: -3px;
}

span.beliver {
  font-family: "Montserrat-SemiBold", sans-serif;
}

.text-right p {
  font-size: 24px;
  color: #363535;
  font-family: "Montserrat-Regular";
  font-weight: 500;
}

/*.new-line-md:hover {
    transform: scale(1.1);
    transition: 0.5s ease-in-out;
}*/
div#owl-demo1 .owl-dots.disabled {
  display: none !important;
}

.navbar-brand {
  padding: 0;
  margin: 0;
}

.social-icons img {
  /* margin-right: 29px; */
  margin-right: 16px;
  margin-left: 16px;
}

header.fixed-top.clearHeader.header-view .imgLog {
  height: 60px;
  width: 60px;
  object-fit: contain;
}

header.fixed-top.clearHeader.header-view {
  height: 74px;
}

.imageLogo:hover {
  transition: none !important;
  transform: none !important;
}

.imageLogo {
  margin-top: -3px;
}

/* .imgPhone{
    height: 400px;
    width: 400px;
    object-fit: contain;
} */
.imgPhone:hover {
  transition: none !important;
  transform: none !important;
}

/* 
.imageDownload{
    height: 50px;
    width: 120px;
    object-fit: contain;
} */
div#collapsibleNavbar ul li a p {
  margin-bottom: 0;
}

.navbar-brand {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 0 !important;
}

.navbar {
  padding: 0.5rem 1rem !important;
}

.new_navbar {
  padding: 0 !important;
}

.slick-slide img {
  display: block;
  width: 100%;
}

.iphone-frame {
  position: absolute;
  top: -13px;
  left: 0;
  right: 0;
  margin: auto;
  width: 288px;
  z-index: 9;
}

.wows {
  width: 230px;
  margin: auto;
}

div#slider1 {
  padding-left: 120px;
  width: 330px;
  padding-top: 67px;
}

.heading_text {
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
  color: #000;
}

section.slider.support {
  height: "100%";
}

.ant-spin-dot-item {
  background-color: white;
}

.thank_you {
  height: 100vh;
}

.form-group p {
  margin: 0 !important;
  /* line-height: 0!important; */
  display: inline-block;
  /* padding-bottom: 14px; */
}

.fr8logo {
  height: 30px;
  width: 30px;
  margin-left: 10px;
}

textarea#message {
  /* margin-top: 17px; */
  margin-bottom: 0;
}

/* section.slider{
    min-height: 100vh;
} */
section.slider {
  height: 606px;
}

.transparent {
  /* background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 10px; */
  background-color: rgba(0, 0, 0, 0.7);
  padding: 24px;
  border-radius: 10px;
  text-align: center;
}

.wrapper_form {
  background-color: #fff;
  margin-top: 79px;
  padding-top: 23px;
}

.wrapper_form .container {
  width: 92%;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1380px;
    width: 86%;
  }
}

.sub-nav {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 30px; */
  /* min-height: 50px;
    padding-bottom: 15px; */
}

.breadcrumbs li {
  color: #666;
  display: inline;
  font-weight: 300;
  font-size: 13px;
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumbs li a {
  color: #555555;
}

.breadcrumbs li+li::before {
  content: ">";
  margin: 0 4px;
}

.text_search {
  position: relative;
}

.text_search img.icons {
  position: absolute;
  /* left: 11px; */
  /* top: 12px; */

  top: 50%;
  transform: translateY(-50%);
  left: 19px;
  font-size: 16px;
  color: #dde3ec;
}

.search_top {
  border: 1px solid #ddd;
  border-radius: 30px;
  box-sizing: border-box;
  color: #999;
  height: 40px;
  padding-left: 40px;
  padding-right: 20px;
  -webkit-appearance: none;
  width: 300px;
  font-size: 13px;
}

.search_top::before {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  color: #ddd;
  content: "\f024";
  font-size: 18px;
  position: absolute;
  font-family: FontAwesome;
  left: 15px;
}

.from_line .form-group label {
  display: inline-block;
  /* margin-bottom: 0.5rem; */

  font-size: 13px;
  margin-bottom: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif;
}

.from_line input.form-control {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  font-weight: 300;
  height: 40px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif;
}

.from_line .form-group {
  margin-top: 25px;
}

.from_line {
  max-width: 770px;
}

.from_line input[type="submit"] {
  background-color: #000000;
  border: 0;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 2.72;
  min-width: 190px;
  padding: 0 1.9286em;
}

.from_line h2 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif;
  font-weight: 400;
  font-size: 32px;
}

.from_line input.form-control:focus {
  border: 1px solid #000;
  border-color: inherit !important;
}

.from_line textarea.form-control:focus {
  border: 1px solid #000;
  border-color: inherit !important;
}

.footer {
  border-top: 1px solid #ddd;
  /* margin-top: 60px; */
  /* padding-top: 60px; */
  padding: 30px 0;
}

.footer-inner {
  text-align: center;
}

.footer a {
  color: #666;
}

.successFooter {
  position: fixed;
  /* top: 0; */
  bottom: 0;
  left: 0;
  right: 0;
}

p#request_description_hint {
  color: #666;
  font-size: 12px;
  margin: 5px 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif;
  line-height: 15px !important;
}

.from_line .form-group label::after {
  content: "*";
  color: #f00;
  margin-left: 2px;
}

.error-text {
  color: #f00;
  text-align: left;
  margin-left: 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 15px !important;
}

@media (max-width: 250px) {
  .button-lite {
    min-width: unset;
  }
}

.text_support h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 32px;
}

.text_support {
  text-align: center;
  width: 100%;
  margin-top: 100px;
}

.text_support p {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 6px;
}

.text_support input[type="form-control"] {
  max-width: 640px;
  height: 40px;
  border-radius: 30px;
  padding-left: 43px;
  border: 0;
  width: 100%;
  font-size: 13px;
}

.text_line_new {
  display: inline-block;
  width: 100%;
  max-width: 640px;
  position: relative;
}

.topic_next {
  text-align: center;
  padding: 33px 0 00;
}

.topic_next p {
  margin-top: 29px;
}

.text_line .col-md-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 21%;
}

.support_text {
  /* background-image: url("../public//images/portNew.jpg"); */
  background-position: center;
  background-size: cover;
  height: 400px;
  padding: 0 20px;
  text-align: center;
  width: 100%;
  margin-top: 74px;
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.3);
}

img.icons-new {
  position: absolute;
  left: 18px;
  top: 12px;
}

h3.collapsible-sidebar-title.sidenav-title {
  font-size: 15px;
  font-weight: 600;
}

.tabs-left>li.active {
  background-color: #000000;
  border-radius: 4px;
}

.tabs-left>li:hover {
  background-color: #000000;
  border-radius: 4px;
}

ul.nav.nav-tabs.tabs-left.sideways li a {
  color: #333;
}

ul.nav.nav-tabs.tabs-left.sideways li a:hover,
ul.nav.nav-tabs.tabs-left.sideways li.active a {
  color: #fff !important;
}

.tabs-left {
  border-bottom: none;
}

.tabs-left>li {
  float: none;
  margin: 0px;
  width: 100%;
  margin-bottom: 3px;
}

.tabs-left>li a {
  border-radius: 4px;
  color: #333333;
  display: block;
  font-weight: 400;
  padding: 11px 9px;
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif;
}

.tab-content p {
  font-size: 15px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif;
  color: #333333;
  line-height: 1.6;
  white-space: pre-wrap;
}

.tab-content {
  width: 88%;
}

/* .adsView{
  width: 100%;

} */
:focus {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.search_box_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search_box {
  background-color: #fff;
  /* height: 250px; */
  min-height: 71px;
  width: 620px;
  z-index: 999;
  margin-top: 5px;
  padding-top: 15px;
  border-radius: 8px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 10px;
  box-shadow: 1px 1px 15px -5px #000;
}

/* .mobile-margin{

} */

.smallbtn {
  padding: 5px;
}

@media (max-width: 762px) {
  .hide-store {
    display: none !important;
  }

  .mobile-icons {
    display: block !important;
  }

  .mobile-margin {
    margin-top: 60px;
    /* margin-left: -50px; */
  }
}

@media (max-width: 500px) {
  .smallbtn {
    height: 50px;
    width: 120px;
    padding: 5px;
  }
}

.search_items {
  font-size: 14px;
  font-weight: 300;
  color: #000;
  margin-bottom: 15px;
}

.search_items:hover {
  cursor: pointer;
  color: #368efa;
}

.no_article {
  font-size: 14px;
  font-weight: 300;
  color: #ccc;
}

.top_Article_suggestion {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
  color: #ccc;
  padding-bottom: 5px;
  border-bottom: 1px solid #e6e6e6;
  width: 100%;
  text-align: left;
}

/* .no_article:hover{
    cursor: pointer;
    color: #ccc;
  } */

/* home style.css */
/* @font-face {
    font-family: 'arial';
    src: url('../public/fonts/arial.woff2')  format('woff2'),
         url('../public/fonts/arial.woff') format('woff'),
         url('../public/fonts/arial.ttf')  format('truetype');
  }
  @font-face {
    font-family: 'Poppins-SemiBold';
    src: url('../public/fonts/Poppins-SemiBold.woff2') format('woff2'),
         url('../public/fonts/Poppins-SemiBold.woff') format('woff'),
         url('../public/fonts/Poppins-SemiBold.ttf')  format('truetype');
  }
  @font-face {
    font-family: 'Poppins-Bold';
    src: url('../public/fonts/Poppins-Bold.woff2') format('woff2'),
         url('../public/fonts/Poppins-Bold.woff') format('woff'),
         url('../public/fonts/Poppins-Bold.ttf')  format('truetype');
  }
  @font-face {
    font-family: 'Poppins-Light';
    src: url('../public/fonts/Poppins-Light.woff2') format('woff2'),
         url('../public/fonts/Poppins-Light.woff') format('woff'),
         url('../public/fonts/Poppins-Light.ttf')  format('truetype');
  }
  @font-face {
    font-family: 'Poppins-Thin';
    src: url('../public/fonts/Poppins-Thin.woff2') format('woff2'),
         url('../public/fonts/Poppins-Thin.woff') format('woff'),
         url('../public/fonts/Poppins-Thin.ttf')  format('truetype');
  } */

body {
  font-family: "arial", sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

header {
  padding: 8px 0 8px;
  position: absolute;
  width: 100%;
  z-index: 99;
  top: 0;
}

div#collapsibleNavbar ul li .nav-link {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  transition: 0.5s;
  padding: 0;
  font-family: "arial";
}

div#collapsibleNavbar ul li {
  padding: 0px 0px 0px 25px;
}

section.sliderNew {
  /* background-image:  url("../public/images/slider-bg.png"); */
  padding: 120px 0 0px 0;
  position: relative;
  padding-top: 160px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /*height: 658px;*/
  background-size: 100% 100%;
}

section.sliderNew h2 {
  font-size: 43px;
  color: #fff;
  font-weight: 600;
  line-height: 50px;
  font-family: "Poppins-SemiBold";
}

section.sliderNew p {
  font-size: 18px;
  text-transform: capitalize;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 100;
  color: #fff;
  line-height: 30px;
  font-family: "Poppins-Light";
}

.check_line span img {
  width: 22px;
}

.mt_top {
  margin-top: 66px;
}

p.BLUE {
  background-color: #368efa;
  color: #fff;
  font-size: 14px;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 0 !important;
}

.Mobile_1 {
  position: relative;
}

.margin-top-40 {
  display: block;
  margin-top: 40px;
  width: 100%;
}

.second_section h3 {
  font-size: 21px;
  color: #7f1223;
  font-weight: 500;
  margin-top: -11px;
  margin-bottom: 9px;
  font-family: "Montserrat-Medium", sans-serif;
}

.second_section p {
  font-size: 14px;
  color: #7f1223;
  font-weight: 500;
  line-height: 19px;
  font-family: "Montserrat-SemiBold", sans-serif;
  padding: 0 16px;
}

.second_box {
  padding: 2px 15px 17px;
  display: block;
  width: 100%;
  transition: 0.5s;
  border-radius: 8px;
}

.second_box:hover {
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
}

section.second_section {
  padding-bottom: 72px;
  /*        background-color: #f4f4f4;
  */
}

button.btn.btn-primary {
  background-color: #f1801f;
  border-color: #f1801f;
  font-size: 21px;
  font-weight: 300;
  border-radius: 50px;
  padding: 0px 38px;
  height: 60px;
}

button.btn.btn-primary.bg-white:hover {
  background-color: #f1801f !important;
  color: #fff !important;
  border-color: #f1801f !important;
}

button.btn.btn-primary.bg-white,
button.btn.btn-primary:hover {
  background-color: #fff;
  color: #f1801f;
  border-color: #f1801f;
}

section.clean_interface p {
  font-size: 16px;
  color: #515151;
  line-height: 26px;
  font-weight: 400;
  margin-top: 31px;
  margin-bottom: 50px;
}

.mobile_circle {
  height: 360px;
  width: 360px;
  background-color: #f1801f;
  border-radius: 50%;
  border: 100px solid #f8bf8f;
  box-sizing: content-box;
  text-align: center;
  position: relative;
  float: right;
}

.mobile_circle img {
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
  left: 0;
  bottom: 0;
}

.align-item-center {
  align-items: center;
}

section.clean_interface {
  padding-bottom: 105px;
}

.mobile-cover {
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  margin: 10px auto 0px;
  z-index: 999;
  text-align: center;
  width: 261px;
}

/*
  section.screenshot .owl-item.active.center .item {
      overflow: hidden;
      border-radius: 20px;
      height: 488px;
      width: 227px;
      margin-left: -14px;
      margin-top: 0px;
  }
  
  section.screenshot .owl-item.active.center .item img {
      transform: scale(1.1);
       transition: 0.5s;
  }*/

section.screenshot .owl-carousel.owl-theme.owl-loaded.owl-drag {
  margin-top: -23px;
}

section.screenshot p.heading-paragraph {
  margin-bottom: 60px;
}

section.screenshot .owl-dots button {
  height: 20px;
  width: 20px;
  background-color: #fbd0aa !important;
  display: inline-block;
  border: 0px solid red !important;
  border-radius: 100px;
  margin: 4px;
}

section.screenshot .owl-dots button.active {
  background-color: #f1801f !important;
}

section.screenshot .owl-dots {
  text-align: center;
  margin-top: 100px;
}

/* section.screenshot {
      padding-bottom: 0px!important;
      background-image: url("../public/images/help-bg.png");
      padding: 33px 0;
      object-fit: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 100% 100%;
  } */

section.testmonials {
  background-color: #f3f3f3;
  padding-top: 90px;
  padding-bottom: 90px;
}

section.testmonials .owl-carousel .owl-item img.quotes_png {
  height: 80px;
  width: auto !important;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.5s;
  transform: translateY(100px);
}

section.testmonials .owl-carousel .owl-item img.quotes_2 {
  height: 80px;
  width: auto !important;
  position: absolute;
  bottom: 0;
  left: 0;
}

section.testmonials .owl-carousel span.profile_pic {
  display: block;
  height: 70px;
  width: 70px;
  border: 0px solid red;
  border-radius: 70px;
  overflow: hidden;
  display: inline-block;
  margin: 0 auto 25px;
}

section.testmonials .owl-carousel span.Name {
  font-size: 21px;
  color: #222222;
  font-weight: 400;
}

section.testmonials .owl-carousel span.Name {
  font-size: 21px;
  color: #222222;
  font-weight: 400;
  display: block;
  text-align: left;
  /* clear: left; */
}

section.testmonials h2.heading {
  margin-bottom: 50px;
}

section.testmonials .owl-carousel p {
  font-size: 16px;
  color: #515151;
  font-weight: 400;
  margin-top: 7px;
  height: 120px;
  display: block;
  text-align: left;
  overflow: hidden;
}

#slider2 .item {
  position: relative;
  padding-bottom: 90px;
  background-color: #fff;
  box-shadow: 0px 3px 6.51px 0.49px rgba(0, 0, 0, 0.26);
  padding: 20px 20px 95px;
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
  transition: 0.5s;
}

#slider2 .owl-item {
  padding: 13px;
}

#slider2 .owl-item:hover .item {
  background-color: #f1801f;
}

#slider2 .owl-item:hover .item span.Name,
#slider2 .owl-item:hover .item p {
  color: #fff;
}

#slider2 .owl-item:hover img.quotes_2 {
  display: none;
}

#slider2 .owl-item:hover .quotes_png {
  transform: translateY(0px);
  transition: 0.5s;
}

/* .footer-box {
      background-image: url("../public/images/footer-bg.png");
      padding-top: 80px;
      text-align: center;
      padding-bottom: 80px;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: cover;
      background-attachment: fixed;
  } */

.footer-box h2 {
  font-size: 38px;
  color: #fff;
  text-align: center;
}

.footer-box p {
  font-size: 16px;
  color: #fff;
  font-weight: 300;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 27px;
}

.footer-bottom ul li {
  display: inline-block;
  padding: 0px 7px;
}

section.foter_bottom {
  border-bottom: 3px solid #fff;
  padding-bottom: 10px;
}

section.foter_bottom2 {
  text-align: center;
  padding: 21px 0;
  color: #fff;
}

section.foter_bottom2 p {
  margin-bottom: 0px;
}

/*
  
  :hover {
      transform: translate(0px, -3px);
      opacity: 0.8;
  }*/

section.sliderNew img,
.second_box img,
.footer-bottom ul li img,
.mobile_circle img {
  transition: 0.5s;
}

section.sliderNew img:hover,
.footer-bottom ul li img:hover {
  transform: translate(0px, -3px);
}

.second_box:hover img {
  transform: scale(1.1);
}

.owl-carousel#slider2 .owl-item img {
  display: block;
  width: 100%;
  object-fit: cover;
  height: 80px;
}

.mobile_circle:hover img {
  transform: scale(0.9);
}

.Mobile_1.slidetilUp.wow img {
  width: 68%;
  position: relative;
  top: -51px;
  right: 0;
  text-align: left;
  margin-right: auto;
}

/*.col-sm-5.text-center {
      position: absolute;
      top: 102px;
      right: 82px;
  }
  */

.container-fluid.adjust {
  max-width: 95%;
  width: 100%;
}

/* .button-lite {
      padding: 12px 20px;
      border-radius: 31px;
      color: #fff;
      font-weight: 600;
      background-image: linear-gradient(#b8283f, #821324);
  }
   */
section.second_section .second_box {
  box-shadow: 0px 0px 77.08px 16.92px rgba(127, 18, 50, 0.14);
}

section.screenshot .heading img {
  margin-right: 9px;
  position: relative;
  top: -2px;
}

section.screenshot h2 {
  margin-top: 7px;
  margin-bottom: 65px;
}

#more,
#more1,
#more2 {
  display: none;
}

button#myBtn,
button#myBtn1,
button#myBtn2 {
  border: 0;
  color: #7f1223;
  font-weight: 700;
  font-family: "Montserrat-SemiBold", sans-serif;
}

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

p#line {
  min-height: 187px;
}

.second_box {
  min-height: 483px !important;
}

span.spaceer {
  margin-top: 5px !important;
  display: block;
}

/*.online-doctor{
      background-image: url(../public/images/image.png);
      padding: 206px 0 202px 0;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
  }*/

/* footer {
      background-image: url("../public/images/footer.png");
      padding: 146px 0 106px 0;
      position: relative;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      background-color: #fafafa;
  } */

.menu a {
  color: #7f1223;
  font-weight: 500;
  padding-right: 17px;
  font-family: "Montserrat-SemiBold", sans-serif;
}

.col-sm-6.text-right.slideInLeft.wow a {
  color: #7c1020;
  font-weight: 500;
  font-family: "Montserrat-SemiBold", sans-serif;
}

section.foter_bottom2 p {
  margin-bottom: 0px;
  color: #545454;
  border-top: 1px solid #7f1223;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;

  padding-top: 14px;
}

section.online-doctor img {
  width: 100%;
}

section.online-doctor {
  margin-top: 146px;
}

.darkHeader {
  background-color: #fff;
}

header.fixed-top.clearHeader.sticky {
  background-color: rgba(0, 0, 0, 1) !important;
  box-shadow: 0px 0px 77.08px 16.92px rgb(127 18 50 / 14%);
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: block;
}

button.owl-prev {
  position: absolute;
  right: 0;
  top: 36%;
}

button.owl-next {
  position: absolute;
  left: 0;
  top: 35%;
}

i.fa.fa-angle-left,
i.fa.fa-angle-right {
  font-size: 62px;
  color: #7f1223;
}

.owl-dots.disabled {
  display: none !important;
}

.socail-media h2 {
  font-size: 21px;
  color: #7f1223;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
}

.socail-media {
  text-align: center;
  margin-bottom: 33px;
}

.owl-carousel.owl-drag .owl-item {
  width: 481px;
  height: 436px;
  border-radius: 12px;
}

.owl-carousel .owl-item img {
  border-radius: 12px;
  height: 351px;
  width: 485px;
  margin: auto;
}

/* section.slider.about-us {
      background-image: url(../public/images/about.png);
      padding: 221px 0 160px 0;
  } */

.text-center.about {
  text-align: center;
  margin: auto;
}

.text-center.about.heading h2 {
  color: #fff;
}

.headings h2 {
  color: #fff !important;
  line-height: 21px;
}

section.sliderNew .headings p {
  font-size: 18px;
  color: #fff;
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  font-family: "Montserrat-Medium", sans-serif;
}

.about img {
  width: 100%;
}

.col-sm-6.text-right p {
  text-align: left;
  color: #757373;
  font-size: 14px;
}

.about-us-page {
  padding-top: 0px;
  background-color: #fff;
  padding-bottom: 0px;
  margin-top: -22px;
}

.about-right {
  padding-top: 108px;
}

.about-us-page p {
  font-size: 15px;
  text-align: left;
  color: #757373;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
}

/*header.fixed-top.clearHeader {
      background-color: #fff;
  }*/

p.line i {
  font-size: 19px !important;
  color: #fff !important;
  position: relative;
  top: 2px;
  padding: 0 5px;
}

.container.adjust {
  max-width: 1310px;
}

.chronic_text h2 {
  font-size: 18px;
  color: #5a5959;
  font-weight: 500;
  text-align: center;
  margin: auto;
  margin-top: 19px;
  font-family: "Montserrat-Medium", sans-serif;
}

section.chronic_text {
  padding: 39px 0;
}

span.color {
  color: #7f1223;
  font-weight: 700;
  opacity: 1;
  font-family: "Montserrat-SemiBold", sans-serif;
}

/* section.chronic_text {
      background-image: url(../public/images/bg-color.png);
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: cover;
      padding: 0 0 54px 0;
  } */

.chronic-box {
  max-width: 650px;
  margin: auto;
  padding-top: 36px;
  text-align: center;
}

.chronic-box p {
  color: #5a5959;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
}

.chronic-box h2 {
  margin-bottom: 21px;
}

.chronic-box .container.adjust h2 {
  font-size: 26px;
}

.col-sm-5.text-right.slideInLeft.chronic-left {
  text-align: left;
  padding-left: 63px;
  margin-top: 209px;
}

.chronic-left h2.heading {
  text-align: left;
}

.about-us-page.chronics {
  background-color: #f4f4f4;
}

.outer-text {
  background: linear-gradient(to bottom, #e4596e 43%, #811324 95%);
  padding: 34px 48px;
  height: 128px;

  border-radius: 7px;
}

input.form-control {
  height: 56px;
  border-radius: 30px;
  border: 0;
  font-size: 11px;
  color: #000 !important;
}

.message-box {
  position: relative;
}

.message-box button {
  border: 0;
  width: 155px;
  height: 54px;
  /* background-color: red; */
  background-image: linear-gradient(#b8283f, #821324);
  color: #fff;
  border-radius: 30px;
  position: absolute;
  right: 49px;
  top: 35px;
  font-size: 19px;
  line-height: 17px;
  font-family: "Montserrat-Medium", sans-serif;
}

.message-box {
  margin-top: 39px;
}

.slider.join {
  /* background-image: url(../public/images/join-bg.png); */
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  padding: 262px 0 250px 0;
}

.wrap {
  text-align: left;
}

.col-sm-5.text-right.slideInLeft.chronic-left.join {
  margin-top: 62px;
}

.wrap.new-line p {
  color: #7f1223;
  text-decoration: underline;
}

.wrap h4 {
  color: #1f1f1f;
  font-size: 24px;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
}

.about-us-page.joiner {
  box-shadow: 0px 0px 26px 14px rgb(127 18 35 / 9%);
  padding-top: 26px;
  background-color: #fff;
  padding-bottom: 66px;
}

.new-fine img {
  width: 100%;
  box-shadow: 0px 0px 77.08px 16.92px rgb(127 18 35 / 14%);
}

.new-fine h2 {
  color: #fff;
  font-weight: 600;
  font-size: 40px;
}

.new-fine p {
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
}

.new-fine {
  position: relative;
}

.img_div {
  position: absolute;
  bottom: 37px;
  text-align: center;
}

section.board {
  margin-top: -93px;
  padding-bottom: 49px;
}

.joiner h2.heading {
  font-weight: 600;
  margin-bottom: 62px;
}

.joiner .about img {
  width: 93%;
  box-shadow: 10px 33px 107.08px 8.92px #fcf5f7;
  border-radius: 6px;
  height: 447px;
}

.joiner .container.adjust {
  max-width: 1240px;
}

footer.join_footer {
  background-color: #fff;
}

section.sliderNew.contactus {
  /* background-image: url(../public/images/contactus.png); */
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  padding: 262px 0 250px 0;
}

.img-shad {
  /* background-image: url(../public/images/box.png); */
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: inherit;
  padding: 27px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  height: 212px;
}

.text-data {
  box-shadow: 0px 0px 26px 14px rgb(127 86 50 / 9%);
  margin-top: -92px;
  background-color: #fff;
  padding: 55px 131px;
  position: relative;
  overflow: hidden;
}

.contact-us input.form-control {
  box-shadow: 0px 0px 26px 14px rgb(127 18 35 / 9%) !important;
  padding-left: 27px;
  padding-right: 27px;
  font-size: 14px;
  height: 57px;
  margin-bottom: 28px;
}

.contact-us textarea.form-control {
  height: 200px;
  resize: none;
  box-shadow: 0px 0px 26px 14px rgb(127 18 35 / 9%) !important;
  border: 0;
  border-radius: 20px;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  padding-left: 27px;
  padding-right: 27px;
  padding-top: 15px;
  margin-bottom: 29px;
}

.contact-us textarea.form-control::placeholder {
  color: #a6a4a4;
}

.contact-us input.form-control::placeholder {
  color: #a6a4a4;
}

.social-icons-new {
  text-align: center;
  margin-top: 71px;
}

.social-icons-new .button-lite {
  padding: 12px 40px;
}

.chronic-box.box .color {
  font-weight: 500;
}

.chronic-box.box {
  max-width: 971px;
}

.wrapper-box {
  max-width: 723px;
  margin: auto;
  text-align: center;
}

.doctor {
  text-align: center;
  margin: auto;
}

.chronic-box.box .doctor p {
  margin-bottom: 7px;
  font-size: 16px;
  color: #434242;
}

.chronic-box.box .doctor h2 {
  margin-bottom: 8px;
  font-size: 24px;
  font-family: "Montserrat-Medium", sans-serif;
}

.chronic-box.box p.lines {
  color: #7f1223;
  font-weight: 500;
  font-size: 17px;
  font-family: "Montserrat-Medium", sans-serif;
}

.chronic-box.box p {
  color: #5a5959;
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
  text-align: justify;
}

.img_map img {
  width: 100%;
}

.message-box {
  margin-top: 11px;
}

.message_earned {
  max-width: 743px;
  margin: auto;
  margin-top: 24px;
}

.new-message p {
  color: #757373;
  text-align: center;
  padding: 13px 0;
  font-size: 17px;
  font-weight: 500;
  font-size: 15px;

  font-family: "Montserrat-Medium", sans-serif;
}

.message-box.box {
  margin-bottom: 33px;
}

.new-message h2.heading {
  margin-top: 23px;
}

.message_earned.new {
  margin-top: 0;
}

.message-box.box button {
  background-color: #e4596e !important;
  background-image: none;
}

.modal-body.new-div {
  display: flex;
  justify-content: space-between;
}

.new-div img {
  width: 40%;
  margin-top: 19px;
}

.modal-header img {
  position: absolute;
  top: -16px;
  right: -18px;
  opacity: 1;
}

h3.doctor {
  margin: 104px 0 45px 0;
  color: #7f1223;
  font-size: 21px;
}

p.Download {
  font-weight: 500;
  margin-bottom: 29px;
}

.modal-body {
  text-align: center;
}

.modal-dialog {
  max-width: 686px;
  margin-top: 150px;
}

.new-div {
  display: flex;
  justify-content: space-around;
  width: 93%;
  margin: auto;
}

button.close {
  opacity: 1;
}

.modal-header {
  border-bottom: 0;
}

.modal-content {
  border-radius: 13px;
}

button.close {
  opacity: 1;
}

/*.modal-open {
      overflow: auto;
  }*/

div#collapsibleNavbar ul li.active a {
  color: #368efa;
  font-weight: 700;
  font-family: "arial";
}

ul.navbar-nav {
  white-space: nowrap;
}

.social-icons a:hover {
  color: #fff;
  text-decoration: none;
}

.openChatBtn {
  background-color: rgb(123, 28, 179);
  color: white;
  padding: 16px 20px;
  border: none;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
  max-height: calc(100vh - 123px);
}

.openChat {
  display: none;
  position: fixed;
  bottom: 120px;
  right: 15px;
  box-shadow: 0px 0px 77.08px 16.92px rgb(127 18 50 / 14%);
  z-index: 999999;
  max-height: calc(100vh - 124px);
  overflow: auto;
  max-width: 400px;
  width: 100%;
}

form {
  /* max-width: 300px; */
  /* padding: 10px; */
  /* background-color: white; */
}

form textarea {
  width: 100%;
  font-size: 18px;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  font-weight: 500;
  background: #d5e7ff;
  color: rgb(0, 0, 0);
  resize: none;
  min-height: 200px;
}

form textarea:focus {
  /* background-color: rgb(219, 255, 252); */
  outline: none;
}

form .btn {
  background-color: rgb(34, 197, 107);
  color: white;
  padding: 16px 20px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

form .close {
  background-color: red;
}

form .btn:hover,
.openChatBtn:hover {
  opacity: 1;
}

.button_live {
  position: fixed;
  right: 50px;
  bottom: 50px;
}

.openChat.block {
  display: block;
}

.openChat .wrapper {
  max-width: 400px;
  background-color: #e2d2d7;
  width: 100%;
}

.openChat textarea.form-control {
  height: 200px;
  border-radius: 12px;
  resize: none;
  color: #000;
}

.openChat h1 {
  background-color: #ad2439;
  color: #fff;
  font-size: 27px;
  font-weight: 400;
  padding: 16px;
}

.chat_line {
  padding: 12px 21px 12px 26px;
}

.form-control:focus {
  border-color: none !important;
  box-shadow: none;
}

.chat_line input.form-control {
  font-size: 15px;
}

.box-color {
  margin: 20px;
  background-image: linear-gradient(#b8283f, #821324);
  padding: 10px 10px 3px;
  box-shadow: 0 0 12px #c38a93;
  border-radius: 14px;
  color: #fff;
}

.social-icons.button {
  margin-top: 42px;
  text-align: center;
}

p.text-fine {
  font-size: 13px;
  padding-top: 37px;
  color: #000;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
  text-shadow: none;
}

.owl-dots {
  display: none;
}

section.second_section .second_box img {
  width: 200px;
  height: 200px;
}

h4.new-liness {
  text-align: center;
  font-size: 20px;
  color: #8c2939;
  margin-top: 14px;
  font-weight: 600;
}

.wrap.new-line p a {
  color: #991c30;
  font-family: "Montserrat-SemiBold", sans-serif;
}

header.fixed-top.clearHeader {
  background-color: #fff;
}

header.fixed-top.clearHeader.header-view {
  background-color: transparent;
}

.maps {
  box-shadow: 0 0 12px #bbbbbb;
  margin-top: 17px;
  position: relative;
}

.button.lites.color {
  padding: 12px 14px;
  border-radius: 27px;
  background-image: linear-gradient(#b8283f, #821324);
  top: 103px;
  position: absolute;
  color: #fff;
  left: 12px;
}

.directions-card.directions-card-medium-large {
  display: none !important;
}

.button.lites.color img {
  padding-right: 8px;
}

img.lite-img {
  position: absolute;
  bottom: -89px;
  right: -51px;
  z-index: 9;
  width: 17%;
}

sup.top-text {
  font-size: 31px;
}

span.radius {
  text-transform: lowercase;
}

.second_section button {
  background-color: transparent;
}

nav.navbar.navbar-expand-lg {
  padding-left: 0;
}

/*div#collapsibleNavbar ul li a:hover{
          color: #7f1223;
  
  }*/
.headings.join {
  margin-top: 39px;
}

.img_div.last {
  position: absolute;
  bottom: 85px !important;
}

.socail-media a:nth-child(2) {
  margin-right: 19px;
}

.chronic-box .container.adjust h2 img {
  position: relative;
  top: -2px;
  padding-right: 5px;
}

.doctor p {
  text-align: center !important;
}

.new-message h2 img {
  margin-top: -4px;
  padding-right: 8px;
}

.outer-text input {
  font-size: 14px;
  font-family: "Montserrat-Medium", sans-serif;
  font-weight: 500;
  padding-left: 29px;
  padding-right: 182px;
}

section.sliderNew.chronic .about .headings {
  margin-top: 100px;
}

.about-us-page.chronics .heading img {
  position: relative;
  top: -2px;
  padding-right: 7px;
}

h2.heading.import .color {
  font-size: 32px;
}

h2.heading.import img {
  position: relative;
  top: -6px;
  right: 8px;
}

.message-box.small button {
  font-size: 14px;
  max-width: 184px;
  width: 100%;
}

.chronic-box.newBox {
  max-width: 720px;
}

.message-box.small {
  margin-bottom: 19px;
  margin-top: 36px;
}

.message-box.small input {
  padding-right: 207px;
}

.about-right {
  padding-top: 108px;
  padding-right: 35px;
  padding-left: 0;
  margin-left: -16px;
}

section.sliderNew.about-us .headings {
  margin-top: 90px;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: transparent !important;
}

.message_earned.new p {
  margin-bottom: 30px;
}

.message-box.box button {
  width: 195px;
}

.footer-bottom {
  margin-top: 50px;
}

.container-fluid.adjust.cro h2 {
  margin-bottom: 11px;
  margin-top: 0;
}

.new-line-md h2 {
  font-size: 17px;
  color: #7f1223;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
}

.new-line-md p {
  font-size: 15px;
  color: #7f1223;
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif;
}

.new-line-md {
  text-align: center;
  box-shadow: 0px 0px 77.08px 16.92px rgb(127 18 50 / 14%);
}

.new-line-md {
  text-align: center;
  border-radius: 13px;
  transition: 0.4s ease-out;

  /*        min-height: 335px;
  */
  box-shadow: 0px 0px 77.08px 16.92px rgb(127 18 50 / 14%);
}

/*.new-bottom-box {
      box-shadow: 0px 0px 77.08px 16.92px rgb(127 18 50 / 14%);
  }*/
.new-line-md img {
  width: 110px;
  /* object-fit: none; */
}

.new-bottom-box {
  padding: 11px 13px 1px;
}

.new-bottom-box {
  min-height: 161px;
}

section.medicience {
  background-color: #f4f4f4;
  padding: 54px 0 67px 0;
}

/*.logo_flex {
      display: flex;
      justify-content: center;
      align-items: center;
  }*/
img.liner {
  position: absolute;
  left: 0;
  top: -25px;
}

section.medicience .container {
  position: relative;
}

.logo_flex h2 {
  margin-bottom: 63px;
}

.logo_flex h2 img {
  position: relative;
  padding-right: 9px;
  top: -3px;
}

span.beliver {
  font-family: "Montserrat-SemiBold", sans-serif;
}

.text-right p {
  font-size: 24px;
  color: #363535;
  font-family: "Montserrat-Regular";
  font-weight: 500;
}

/*.new-line-md:hover {
      transform: scale(1.1);
      transition: 0.5s ease-in-out;
  }*/
div#owl-demo1 .owl-dots.disabled {
  display: none !important;
}

.navbar-brand {
  padding: 0;
  margin: 0;
}

.social-icons img {
  margin-right: 29px;
}

.header-view nav a img {
  width: 68px;
}

section.how_work h2 {
  font-family: "Poppins-Bold";
}

section.how_work {
  padding-top: 0;
  margin-bottom: 82px;
}

section.how_work .dotted_bg {
  border-bottom: 3px dashed #165254;
  margin: 20px 0;
}

section.how_work .dotted_bg img {
  width: 57px;
}

section.how_work .create_add {
  text-align: center;
}

section.how_work .create_add img {
  width: 131px;
  cursor: pointer;
  margin-top: 30px;
}

section.how_work .create_add p {
  font-family: "Poppins-Thin";
  font-size: 15px;
  width: 82%;
  margin: auto;
  line-height: 20px;
  padding-bottom: 50px;
  text-transform: inherit;
}

section.how_work .create_add h4 {
  color: #fff;
  margin-top: 20px;
}

section.how_work .create_add {
  /* background-image: url("../public/images/how-bg.png"); */
  /*padding: 120px 0 0px 0;*/
  position: relative;
  /*padding-top: 160px;*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /*height: 658px;*/
  background-size: 100% 100%;
  box-shadow: -10px 13px 34.5px 11.5px rgb(4 4 4 / 58%);
}

section.how_work.three_ad_format li a {
  font-size: 26px;
  color: #fff;
  padding: 14px 0px;
}

section.how_work.three_ad_format .nav-tabs {
  /* border: 2px solid #1a74a8;
      border-radius: 20px;   */

  flex-wrap: nowrap;
  border: 2px solid #1a74a8;
  border-radius: 20px;
  display: flex;
  text-align: center;
}

section.how_work.three_ad_format .nav-tabs .nav-link.border-radius {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

section.how_work.three_ad_format .nav-tabs .nav-link.border-radius-right {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

section.how_work.three_ad_format .nav-tabs .nav-link.active {
  background-color: #1a74a8;
  border-radius: 0;
  border: 0;
  color: #fff;
}

section.how_work.three_ad_format .nav-tabs {
  margin: 42px 0 0;
}

section.how_work.three_ad_format .nav-tabs .nav-link:hover {
  border-color: #1c1c1c #1c1c1c #1c1c1c;
}

section.how_work.three_ad_format .nav-tabs .nav-item {
  margin-bottom: -2px !important;
  width: 100%;
}

section.how_work.three_ad_format .nav-tabs .nav-link {
  border: none;
}

section.how_work.three_ad_format {
  /* background-image: url("../public/images/background.png"); */
  /*padding: 120px 0 0px 0;*/
  position: relative;
  /*padding-top: 160px;*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.search_img {
  text-align: center;
}

section.how_work.three_ad_format .search_img img {
  width: 68%;
}

section.how_work.three_ad_format .know_more button {
  background-color: #1a74a7;
  color: #fff;
  border-radius: 50px;
  border: 0;
  padding: 8px 32px;
  /* box-shadow: 4px 4px 25.26px -29.26px rgb(21 20 20 / 23%); */
  box-shadow: 7px 2px 7.26px 7.74px rgb(25 25 25 / 77%);
  margin-top: 32px;
}

/*section.how_work.three_ad_format .know_more button:hover {
      border: 2px solid #1a74a7;
      background-color: #c1c1c1;
  }*/
section.how_work.three_ad_format .search_para {
  margin-bottom: 34px;
  text-transform: inherit;
}

.border-bottom {
  width: 55%;
}

.terminalBoard.slick-slider {
  width: 95%;
  /* margin-left: 8vw; */
  /* margin-right: 8vw;
  /* width: 1000px; */
  /* align-items: flex-end;
  justify-content: flex-end; */
  /* display: flex; */
}

.terminalBoard.slick-slide img {
  display: block;
  /* width: "100%"; */
}

.terminalBoard.slick-slide.slick-active {
  margin: 0 !important;
}

section.sectionTopTerminalBoardArea {
  /* background-image: url("../assets/images/bgImageWhite.png"); */
  /* padding: 22px 0 0px 0; */
  position: relative;
  padding-top: 0px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
  min-height: 100vh;
  background-size: 100% 100%;
  background-attachment: fixed;
  /* overflow: hidden; */
}

.news {
  /* margin-top: 20px; */
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  /* min-height: 200px; */
  /* position: fixed; */
}

.terminalAreaName {
  color: #3387ed !important;
  font-size: 40px;
  font-weight: bold !important;
  /* line-height: 40px !important; */
  font-family: "Montserrat-Bold", sans-serif, Arial !important;
}

.terminalAreaNameDivider {
  color: #3387ed !important;
  /* font-size: 50px !important; */
  /* margin-top: 5; */
  /* font-weight: bold !important; */
  /* line-height: initial !important; */
  font-family: "Montserrat-Bold", sans-serif, Arial !important;
}

.terminalAreaTime {
  color: #1c1c1d;
  font-size: 40px !important;
  font-weight: bold !important;
  /* width: 10vw !important; */
  font-family: "Montserrat-Bold", sans-serif, Arial;
}

.terminalAreaHeader {
  /* padding: 0% 10%; */
  flex-direction: row;
  padding-top: 80px;
  display: flex;
  /* width: 100%; */
  align-items: center;
  justify-content: space-between;
  /* position: fixed; */
  /* background: chartreuse; */
}

.terminalAreaHeaderSub {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.terminalAreaHeaderTempToday {
  margin-left: 5vw;
  /* align-items: center; */
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.terminalAreaTimeToday {
  color: #1c1c1d;
  font-size: 32px !important;
  margin: 0 !important;
  font-family: "Montserrat-SemiBold", sans-serif, Arial;
  /* font-weight:500 !important; */
}

.tempDiv {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  /* margin-left: -10px; */
}

.terminalName {
  color: #fff !important;
  /* font-size: 20px !important; */
  /* font-weight: bold !important; */
  text-align: center !important;
  margin: 0;
  font-family: "Montserrat-SemiBold", sans-serif, Arial !important;
}

.terminalNameAvg {
  color: #fff !important;
  /* font-size: 30px !important; */
  font-weight: bold !important;
  text-align: center !important;
  font-family: "Montserrat-Bold", sans-serif, Arial !important;
  /* line-height: 36px !important; */
  /* margin-bottom: 30px; */
  /* margin-top: 1%; */
}

.terminalNameAvgTime {
  color: #fff !important;
  /* font-size: 36px !important; */
  font-weight: bolder !important;
  text-align: center !important;
  /* margin-bottom: 10px; */
  font-family: "Montserrat-Bold", sans-serif, Arial !important;
  /* margin-top: 1%; */
}

.terminalNameAvgTimeSmall {
  color: #fff !important;
  /* font-size: 26px !important; */
  font-weight: bolder !important;
  text-align: center !important;
  /* margin-bottom: 10px; */
  font-family: "Montserrat-Bold", sans-serif, Arial !important;
}

@keyframes flickerAnimation {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.animate-flicker {
  -webkit-animation: flickerAnimation 10s infinite;
  -moz-animation: flickerAnimation 10s infinite;
  -o-animation: flickerAnimation 10s infinite;
  animation: flickerAnimation 10s infinite;
  /* font-size: 16px !important; */
  /* line-height: 16px; */
  color: #000 !important;
  font-family: "Montserrat-Regular", sans-serif, Arial !important;
}

.fade-in-image {
  margin-top: 10px !important;
}

.text-img {
  /* margin-left: 5vw; */
}

.terminalAreaImage {
  /* height: 10%;
  width: 70%; */
}

.AnimateHeight {
  border: 2px solid #ddd;
  border-radius: 3px;
}

.terminalMain {
  background-image: url("../assets/images/box.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 1000px; */
  background-size: 100% 100%;
  cursor: pointer;
  /* background-attachment: fixed;  */
  /* width: 6vw; */
  /* border-radius: 15px; */
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* transform: rotate(180deg); */
}

.terminalImage {
  height: 20vh;
  width: 40vh !important;

  /* resize: both; */
  /* border-top: 10px solid hsla(0, 0%, 100%, 0.5);
  background: #fff; */
}

.sun:hover,
.moon:hover,
.terminalAreaImage:hover,
.terminalImage:hover,
.terminalImageLogo:hover {
  transition: none !important;
  transform: unset !important;
}

.terminalImageLogo {
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

.terminalBoard {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

/* new media query for termainal board page*/
@media (max-width: 1199px) {
  .animate-flicker {
    font-size: 16px !important;
  }

  .terminalAreaImage {
    /* height: 10% !important;
    width: 70%; */
  }

  .terminalAreaTimeToday {
    font-size: 20px !important;
  }

  .terminalAreaName {
    font-size: 20px !important;
  }

  .terminalAreaNameDivider {
    font-size: 20px !important;
  }

  .terminalAreaTime {
    font-size: 20px !important;
  }

  .newsSection {
    top: 180px !important;
  }

  .terminalMain {
    /* width: 100px; */
  }
}

@media (max-width: 991px) {
  .animate-flicker {
    font-size: 14px !important;
  }

  .terminalAreaImage {
    /* height: 8% !important;
    width: 70%; */
  }

  .terminalAreaTimeToday {
    font-size: 18px !important;
  }

  .terminalAreaName {
    font-size: 18px !important;
  }

  .terminalAreaNameDivider {
    font-size: 18px !important;
  }

  .terminalAreaTime {
    font-size: 18px !important;
  }

  .newsSection {
    top: 160px !important;
  }

  .terminalMain {
    /* width: 85px; */
  }
}

@media (max-width: 769px) {
  .animate-flicker {
    font-size: 14px !important;
  }

  .terminalAreaImage {
    /* height: 8%;
    width: 70%; */
  }

  .terminalAreaTimeToday {
    font-size: 16px !important;
  }

  .terminalAreaName {
    font-size: 16px !important;
  }

  .terminalAreaNameDivider {
    font-size: 26px !important;
    margin-top: 0px !important;
  }

  .terminalAreaTime {
    font-size: 16px !important;
  }

  .newsSection {
    top: 190px !important;
  }

  .terminalMain {
    /* width: 95px; */
  }

  .screenActive {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .animate-flicker {
    font-size: 12px !important;
  }

  .terminalAreaImage {
    /* height: 6% !important;
    width: 70%; */
  }

  .terminalAreaTimeToday {
    font-size: 14px !important;
  }

  .terminalAreaName {
    font-size: 14px !important;
  }

  .terminalAreaNameDivider {
    font-size: 24px !important;
  }

  .terminalAreaTime {
    font-size: 14px !important;
  }

  .newsSection {
    top: 180px !important;
  }

  .terminalMain {
    display: none;
  }
}

@media (max-width: 575px) {
  .terminalAreaImage {
    /* height: 6% !important;
    width: 50%; */
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .terminalMain {
    display: none;
  }
}

@media (min-width: 576px) {
  .terminalBoard {
    /* max-width: 700px; */
  }

  /* .terminalMain {
    width: 80px;
  } */
}

@media (min-width: 767px) {
  .terminalBoard {
    /* max-width: 720px; */
  }

  .terminalMain {
    /* width: 100px; */
  }
}

@media (min-width: 992px) {
  .terminalBoard {
    /* max-width: 960px; */
  }

  .terminalMain {
    /* width: 140px; */
  }
}

@media (min-width: 1200px) {
  .terminalBoard {
    /* max-width: 1240px; */
  }

  .terminalMain {
    /* width: 190px; */
  }
}

/* .newsText p {
  color: #000 !important;
  text-align: left !important;
  font-family: "Montserrat-Regular", sans-serif, Arial !important;
}
.newsText h2 {
  color: #000 !important;
  font-family: "Montserrat-Regular", sans-serif, Arial !important;
} */
.newsText span {
  /* font-size: 1vw !important;
  line-height: 2vw !important; */
  color: #000;
  font-family: "Montserrat-Regular", sans-serif, Arial;
  /* background-color: transparent !important; */
}

.newsText strong {
  /* font-size: 1vw !important;
  line-height: 2vw !important; */
  color: #000;
  font-family: "Montserrat-Regular", sans-serif, Arial;
  /* background-color: transparent !important; */
}

.newsText p {
  /* font-size: 1vw !important;
  line-height: 2vw !important; */
  color: #000;
  font-family: "Montserrat-Regular", sans-serif, Arial;
  /* background-color: transparent !important; */
}

.newsText a {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

/* .newsText p:first-of-type span {
  font-size: 5vh !important;
  line-height: 7vh !important;
  color: #000 !important;
  font-family: "Montserrat-Regular", sans-serif, Arial !important;
  background-color: transparent !important;
} */

.newsTextHeading span {
  /* font-size: 2vw !important;
  line-height: 3vw !important; */
  /* color: #000; */
  /* font-weight: bold; */
  font-family: "Montserrat-Regular", sans-serif, Arial;
  /* background-color: transparent !important; */
}

.newsTextHeading strong {
  /* font-size: 2vw !important;
  line-height: 3vw !important; */
  /* color: #000 ; */
  /* font-weight: bold; */
  font-family: "Montserrat-Regular", sans-serif, Arial;
  /* background-color: transparent !important; */
}

.newsTextHeading p {
  /* font-size: 2vw !important;
  line-height: 3vw !important; */
  /* color: #000 ; */
  /* font-weight: bold; */
  font-family: "Montserrat-Regular", sans-serif, Arial;
  /* background-color: transparent !important; */
}

.newsTextHeading a {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

/* .MuiSvgIcon-root {
  color: #fff !important;
} */
/* div#__next {
  overflow: hidden !important;
} */
.rdw-editor-main {
  word-break: break-all !important;
}

.pt-4,
.py-4 {
  padding-top: 0 !important;
}

.rdw-editor-wrapper1 {
  background: #efefef !important;
}

.toolBar1 {
  background-color: #efefef;
  /* max-height: 30%; */
  padding: 5%;
}

.rdw-editor-toolbar1 {
  background: #efefef !important;
}

/* .rdw-editor-toolbar {
  background: #efefef !important;
} */

.makeStyles-imageManagementUploaderlabel-45 {
  width: 100px;
  height: 100px;
  margin-bottom: 12px !important;
  margin-top: 12px !important;
}

.makeStyles-linkButton-7 {
  color: #555555;
  width: 50% !important;
  border: none;
  cursor: pointer;
  /* font-size: 1rem; */
  text-align: center;
  font-weight: 500 !important;
  padding-bottom: 8px;
  text-decoration: none;
  transition-duration: 0.4s;
}

.makeStyles-brockenContainer-37 {
  background-image: url("../assets/icons/404.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 80%;
  text-align: center;
  width: 100%;
  margin: auto;
  background-position: center;



}

.makeStyles-brockenContainer-114 {
  background-image: url("../assets/icons/404.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 80%;
  text-align: center;
  width: 100%;
  margin: auto;
  background-position: center;

}

.ErrorFailView {
  background-color: #E8F4FF;
  background-image: url("../assets/icons/crash.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 80%;
  text-align: center;
  width: 100%;
  margin: auto;
  background-position: center;

}

.wrongPageView {
  background-color: #E8F4FF;
  background-image: url("../assets/icons/pageError.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 80%;
  text-align: center;
  width: 100%;
  margin: auto;
  background-position: center;

}

.button-four {
  position: absolute;

  bottom: 5vh;

}

@media (max-width: 576px) {
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
}

.toolbar_editor {
  background-color: #fff;
  /* max-height: 30%; */
  padding: 5%;
}

.toolbar_editor1 {
  background-color: #F1F1F1;
  /* max-height: 30%; */
  padding: 5%;
}

div.ck.ck-toolbar.ck-toolbar_grouping {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  user-select: none;
  background: #F1F1F1 !important;
  width: 668px;
}

/* button.ck.ck-button.ck-off{
  border: 1px solid #F1F1F1;
    padding: 5px;
    min-width: 25px;
    height: 20px;
    border-radius: 2px;
    margin: 0 4px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
} */

div.ck.ck-editor__main {
  min-height: 220px;
  height: 500px;
  overflow-x: auto;
  padding: 5px 13px;
  background: #F1F1F1 !important;
  word-break: break-all !important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: #F1F1F1 !important;
  background: #F1F1F1 !important;
}

.ck.ck-editor__main>.ck-editor__editable {
  background: #F1F1F1 !important;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: none !important;
  box-shadow: none !important;
}

.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
  flex-wrap: inherit !important;
}

.cke_reset_all,
.cke_reset_all *,
.cke_reset_all a,
.cke_reset_all textarea {
  display: none !important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  min-height: 405px;
}

/* .ck.ck-sticky-panel__placeholder{
  display:none !important
}
.ck.ck-dropdown.ck-toolbar__grouped-dropdown.ck-toolbar-dropdown{
  display: none !important;
} */


/* for ck editor */


:root {
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-todo-list-checkmark-size: 16px;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
  max-width: calc(100% - var(--ck-image-style-spacing));
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
  clear: none;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content p+.image-style-align-left,
.ck-content p+.image-style-align-right,
.ck-content p+.image-style-side {
  margin-top: 0;
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
  margin-top: var(--ck-inline-image-style-spacing);
  margin-bottom: var(--ck-inline-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
  margin-right: var(--ck-inline-image-style-spacing);
}

/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
  margin-left: var(--ck-inline-image-style-spacing);
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: .7em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: .85em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}

/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}

/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image>figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: var(--ck-color-image-caption-text);
  background-color: var(--ck-color-image-caption-background);
  padding: .6em;
  font-size: .75em;
  outline-offset: -1px;
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: var(--ck-highlight-marker-green);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: var(--ck-highlight-marker-pink) !important;
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}

/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 0.9em auto;
  min-width: 50px;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image-inline {
  /*
   * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
   * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
   * This strange behavior does not happen with inline-flex.
   */
  display: inline-flex;
  max-width: 100%;
  align-items: flex-start;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
  display: flex;
}

/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized>figcaption {
  display: block;
}

/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  margin: 15px 0;
  height: 4px;
  background: hsl(0, 0%, 87%);
  border: 0;
}

/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}

/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}

/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: .15em;
  border-radius: 2px;
}

/* ckeditor5-table/theme/tablecaption.css */
.ck-content .table>figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: var(--ck-color-table-caption-text);
  background-color: var(--ck-color-table-caption-background);
  padding: .6em;
  font-size: .75em;
  outline-offset: -1px;
}

/* ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 0.9em auto;
  display: table;
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: .4em;
  border: 1px solid hsl(0, 0%, 75%);
}

/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}

/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
  text-align: right;
}

/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
  text-align: left;
}

/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: '';
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}

/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: .3em .6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 0.9em 0;
  display: block;
  min-width: 15em;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  margin-bottom: 5px;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label>input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label>input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: '';
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label>input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: '';
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  transform: rotate(45deg);
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label>input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label>input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}

/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}

/* ckeditor5-language/theme/language.css */
.ck-content span[lang] {
  font-style: italic;
}

/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}

/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}

/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: var(--ck-color-mention-background);
  color: var(--ck-color-mention-text);
}

@media print {

  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }

  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
}

.news_listing>figure.image.image_resized>img {
  /* display: none; */
  height: 100px;
  width: 100px;
  min-width: 100px !important;
}

.news_listing>figure.image.image_resized.image-style-side>img {
  /* display: none; */
  height: 100px;
  width: 100px;
  min-width: 100px !important;
}

.news_listing>p>span>img {
  /* display: none; */
  height: 100px;
  width: 100px;
  min-width: 100px !important;
}

.news_listing>p>span>mark.marker-green>img {
  /* display: none; */
  height: 100px;
  width: 100px;
  min-width: 100px !important;
}

.news_listing>p>img.image_resized {
  /* display: none; */
  height: 100px;
  width: 100px !important;
  min-width: 100px !important;
}

.news_listing>figure.image>img {
  /* display:none; */
  height: 100px;
  width: 100px !important;
  min-width: 100px !important;
  margin-top: 12px;
}

.news_listing>p>img {
  /* display:none; */
  height: 100px;
  width: 100px !important;
  min-width: 100px !important;
}

.news_listing>h2>span>img {
  width: 100px;
  height: 100px !important;
  min-width: 100px !important;
}

/* .news_listing>figure.image.image_resized>img{
  display: none;
 } */


.news_details>figure.image.image_resized.image-style-side>img {
  width: 300px;
  height: 200px;
}

.news_details>p>img.image_resized {
  width: 300px !important;
  height: 200px;
}

.news_details>figure.image>img {
  width: 300px !important;
  height: 200px;
  object-fit: contain;
}

.news_details>p>span>img.image_resized {
  width: 300px !important;
  height: 200px;
}

.news_details>h2>span>img {
  width: 300px !important;
  height: 200px;
}

/* .ck.ck-sticky-panel__content.ck-sticky-panel__content_sticky {
  display: none;
} */

.ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
  position: inherit !important;
}


.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  width: auto;
}

.ck-content.scroll-text {
  /* height: 100%; */
  /* text-align: center; */
  -moz-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);

  -moz-animation: my-animation 10s linear infinite;
  -webkit-animation: my-animation 10s linear infinite;
  animation: my-animation 10s linear infinite;
}

/* @-webkit-keyframes my-animation {
  from { -webkit-transform: translateY(100%); }
  to { -webkit-transform: translateY(-100%); }
} */

@keyframes my-animation {
  from {
    -moz-transform: translateY(10%);
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }

  to {
    -moz-transform: translateY(-80%);
    -webkit-transform: translateY(-80%);
    transform: translateY(-80%);
  }
}

.ck.ck-editor__editable>.ck-placeholder::before {
  /* color: #d21714; */
  /* font-family: Georgia; */
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  text-align: left !important;
}

ul.ck.ck-reset.ck-list {
  height: 200px;
  overflow-x: hidden;
  width: 150px
}

.ck.ck-editor__editable_inline {
  overflow: visible !important;
}

.news_listing {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ck .ck-widget.ck-widget_selected>.ck-widget__type-around>.ck-widget__type-around__button:after,
.ck .ck-widget>.ck-widget__type-around>.ck-widget__type-around__button:hover:after {
  cursor: pointer;
}

.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected>.ck-widget__type-around>.ck-widget__type-around__button:not(:hover) {
  cursor: pointer;
}

.ck .ck-widget .ck-widget__type-around__button svg {
  cursor: pointer;
}

/* .ck .ck-balloon-panel.ck-balloon-panel_arrow_s.ck-balloon-panel_with-arrow.ck-toolbar-container {
  top: 390px !important;
  left: 510px;
} */

.ck.ck-editor__editable_inline {
  border: none !important;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  height: 477px;
}



/* .cke.cke_1.cke_reset_all.cke_chrome.cke_editor_editor1.cke_float.cke_ltr.cke_browser_webkit{
  position: fixed !important;
  top:50px !important;
} */
/* .ck.ck-balloon-panel.ck-balloon-panel_arrowless.ck-balloon-panel_visible.ck-toolbar-container{
top:150px !important;
}
.ck.ck-balloon-panel.ck-balloon-panel_arrow_n.ck-balloon-panel_visible.ck-balloon-panel_arrow.ck-toolbar-container{
  top:300px !important;
  }
  .ck.ck-balloon-panel.ck-balloon-panel_arrow_n.ck-balloon-panel_with-arrow.ck-toolbar-container{
    top:720px !important;
  } */

/* .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners{
  margin-top: -20px !important;
  font-size: 15px;
} */

.new_spin{
  background: rgb(25, 110, 161);
  border: rgb(25, 110, 161);
  height: 37px;
  margin-right: 20px;
  width: 99px;
  margin-top: -3px;
  padding: 0px 27px;
  border-radius: 3px;
}

.ant-spin-lg .ant-spin-dot {
  font-size: 27px;
  margin-top: 4px;
}

/* ant-select-dropdown ant-select-dropdown-empty ant-select-dropdown-placement-bottomLeft  ant-select-dropdown-hidden */

.ant-select-dropdown{
  z-index: 999999 !important;
}